<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog v-model="modalAction" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h6">Cargar materiales desde excel</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.codigo_proyecto.$model"
                  :error="$v.form.codigo_proyecto.$invalid && submitUpload"
                  :items="places"
                  label="Punto de instalación"
                  item-text="code"
                  item-value="code"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-select
                  v-model="$v.form.tipo_solicitud.$model"
                  :error="$v.form.tipo_solicitud.$invalid && submitUpload"
                  clearable
                  :items="optionsTypeDocument"
                  item-text="name"
                  item-value="value"
                  label="Tipo de documento"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-file-input
                  v-model="$v.form.file.$model"
                  :error="$v.form.file.$invalid && submitUpload"
                  label="Archivo (Excel)"
                ></v-file-input>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalUploadMaterialsFromExcel"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            color="blue darken-3"
            :disabled="loading"
            :loading="loading"
            @click="uploadMaterials"
          >
            <small class="text-white">
              Cargar materiales
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { authUsuarioComputed } from '@/state/helpers'
import swal2 from 'sweetalert2'
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false
    },
    closeModalUploadMaterialsFromExcel: {
      type: Function,
      default: () => {}
    }
  },
  validations: {
    form: {
      codigo_proyecto: { required },
      tipo_solicitud: { required },
      file: { required }
    }
  },
  data() {
    return {
      loading: false,
      submitUpload: false,
      optionsTypeDocument: [
        {
          name: 'SOLICITUD DE LLENADO INICIAL',
          value: 'SOLICITUD DE LLENADO INICIAL'
        },
        {
          name: 'SOLICITUD DE MATERIALES',
          value: 'SOLICITUD DE MATERIALES'
        },
        {
          name: 'SOLICITUD DE MATERIAL URGENTE',
          value: 'SOLICITUD DE MATERIAL URGENTE'
        }
      ],
      form: {
        codigo_proyecto: null,
        tipo_solicitud: null,
        file: null
      }
    }
  },
  methods: {
    ...mapActions({
      uploadDocumentMaterialsFromExcel: 'cubage/uploadDocumentMaterialsFromExcel'
    }),
    resetForm() {
      this.form = {
        codigo_proyecto: null,
        tipo_solicitud: null,
        file: null
      }
      this.submitUpload = false
    },
    async uploadMaterials() {
      this.submitUpload = true
      if (!this.$v.form.$invalid) {
        this.loading = true
        let payload = {
          codigo_proyecto: this.form.codigo_proyecto,
          file: this.form.file,
          tipo_solicitud: this.form.tipo_solicitud,
          user: this.user.email
        }
        const resp = await this.uploadDocumentMaterialsFromExcel(payload)
        if (!resp.status || resp.status === 500) {
          swal2.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ha ocurrido un error en la carga masiva, intenta nuevamente',
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
        }
        if (resp.status === 409) {
          swal2.fire({
            icon: 'error',
            title: 'Error',
            text: resp.data.detail,
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
        }
        if (resp.status === 404) {
          swal2.fire({
            icon: 'error',
            title: 'Error',
            text: resp.data.detail,
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
        }
        if (resp.status == 200) {
          swal2.fire({
            icon: 'success',
            title: 'Excelente',
            text: 'Materiales cargados correctamente',
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
          this.$emit('materialsUploaded')
          this.closeModalUploadMaterialsFromExcel()
          this.resetForm()
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      places: 'project/places',
      currentCubage: 'cubage/currentCubage'
    })
  }
}
</script>

<style></style>
