<template>
  <v-container class="p-4 px-1">
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-3">
        Abastecimiento
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center" class="mt-1">
      <v-col cols="12" md="4" sm="12" class="py-1">
        <label for="place_id">
          Centro de Costo - Lugar de instalación
        </label>
        <v-autocomplete
          v-model="filters.place_id"
          :items="places"
          clearable
          @change="($event) => getCubagesData()"
          item-text="code"
          item-value="code"
          searchable
          class=""
          solo
          label="Todos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="py-1">
        <label for="dispatch">
          Despacho
        </label>
        <v-select
          v-model="filters.dispatch"
          @change="($event) => getCubagesData()"
          clearable
          :items="dispatchs"
          item-text="description"
          item-value="description"
          class=""
          solo
          label="Todos"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="py-1">
        <label for="document">
          Tipo de documento
        </label>
        <v-select
          v-model="filters.document_type"
          @change="($event) => getCubagesData()"
          clearable
          :items="optionsTypeDocument"
          class=""
          item-text="name"
          item-value="value"
          solo
          label="Todos"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="py-1">
        <label for="document">
          Estado
        </label>
        <v-select
          v-model="filters.status"
          clearable
          :items="['Asignado', 'SD', 'SC', 'OC']"
          class=""
          solo
          label="Todos"
        ></v-select>
      </v-col>
      <!-- <v-col cols="12" md="4" sm="12" class="py-1">
        <label for="solicitud">
          Número de solicitud
        </label>
        <v-select
          v-model="filters.application_number"
          clearable
          :items="[]"
          class=""
          solo
          label="Todos"
        ></v-select>
      </v-col> -->
      <v-col cols="12" md="4" sm="12">
        <v-btn
          @click="openModalUploadMaterialsFromExcel"
          small
          block
          color="green darken-2 text-white"
          class="float-right"
        >
          Cargar materiales desde Excel
        </v-btn>
      </v-col>
    </v-row>
    <p class="text-right mt-5">
      Número de materiales total:
      {{ allCubageMaterials ? allCubageMaterials.docmaterialcount : 0 }}
    </p>
    <v-data-table
      class="elevation-3 px-3 mt-1"
      :headers="tableHeaders"
      :items="wrappedAllCubageMaterials"
      :items-per-page="20"
      :page.sync="filters.page"
      :loading="loading"
      loading-text="Cargando materiales"
      item-key="id"
      hide-default-footer
      no-data-text="Sin materiales"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.material="{ item }">
        <v-icon small color="yellow darken-2" v-if="item.material && item.material.favorite">
          mdi-star
        </v-icon>
        <span v-else>
          -
        </span>
      </template>
      <template v-slot:item.created_at="{ item }">
        <p class="text-left mt-2">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </p>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" v-bind="attrs" v-on="on">
                <v-icon small>
                  mdi-pincel
                </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.solicitud_erp="{ item }">
        <span>{{ item.solicitud_erp ? item.solicitud_erp : '-' }}</span>
      </template>
      <template v-slot:item.numero_documento="{ item }">
        <span>{{ item.numero_documento ? item.numero_documento : '-' }}</span>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="allCubageMaterials ? allCubageMaterials.docmaterialpages : 1"
      ></v-pagination>
    </div>

    <!-- Modal Upload Materials From Excel -->
    <upload-materials-from-excel
      :modalAction="dialogUploadMaterialsFromExcel"
      :closeModalUploadMaterialsFromExcel="closeModalUploadMaterialsFromExcel"
      @materialsUploaded="getCubagesData"
    ></upload-materials-from-excel>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UploadMaterialsFromExcel from '@/components/proyectos/lugarInstalacion/cubicles/components/UploadMaterialsFromExcel.vue'
export default {
  components: { UploadMaterialsFromExcel },
  mounted() {
    this.getCubagesData()
    this.getAllDispatchs()
    this.getAllProjects()
  },
  watch: {
    'filters.page': function(page) {
      this.getCubagesData(page)
    }
  },
  data() {
    return {
      dialogUploadMaterialsFromExcel: false,
      filters: {
        page: 1,
        place_id: null,
        dispatch: null,
        document_type: null,
        status: null,
        application_number: null
      },
      optionsTypeDocument: [
        {
          name: 'SOLICITUD DE LLENADO INICIAL',
          value: 'SOLICITUD DE LLENADO INICIAL'
        },
        {
          name: 'SOLICITUD DE MATERIALES',
          value: 'SOLICITUD DE MATERIALES'
        },
        {
          name: 'SOLICITUD DE MATERIAL URGENTE',
          value: 'SOLICITUD DE MATERIAL URGENTE'
        }
      ],
      pageCount: 0,
      breadcrumb: [
        {
          text: 'Proyectos',
          disabled: false,
          href: '/'
        },
        {
          text: 'Abastecimiento',
          disabled: true,
          href: '/supplying'
        }
      ],
      tableHeaders: [
        {
          text: 'Número de documento',
          align: 'center',
          sortable: false,
          value: 'numero_documento'
        },
        {
          text: 'Código de producto',
          align: 'center',
          sortable: false,
          value: 'material.code'
        },
        {
          text: 'Descripción',
          align: 'center',
          sortable: false,
          value: 'material.description'
        },
        {
          text: 'Unidad',
          align: 'center',
          sortable: false,
          value: 'material.measurement.units'
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: false,
          value: 'quantity'
        },
        {
          text: 'Centro Costo',
          align: 'center',
          sortable: false,
          value: 'document.place_id'
        },
        {
          text: 'Despacho',
          align: 'center',
          sortable: false,
          value: 'dispatch.description'
        },
        {
          text: 'Tipo de documento',
          align: 'center',
          sortable: false,
          value: 'document_erp.tipo_dcto'
        },
        {
          text: 'Estado',
          align: 'center',
          sortable: false,
          value: 'erp_status'
        },
        {
          text: 'Numero de solicitud',
          align: 'center',
          sortable: false,
          value: 'solicitud_erp'
        },
        {
          text: 'Fecha de creación',
          align: 'center',
          sortable: false,
          value: 'created_at'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      loading: 'cubage/loading',
      allCubageMaterials: 'cubage/allCubageMaterials',
      dispatchs: 'cubage/dispatchs',
      cubages: 'cubage/cubages',
      places: 'project/places'
    }),
    wrappedAllCubageMaterials() {
      return this.allCubageMaterials
        ? this.allCubageMaterials.docmaterials.map((item) => {
            return item.material
              ? {
                  ...item,
                  desired_date: '-'
                }
              : {
                  ...item,
                  material: { ...item.custommaterial, code: '-' },
                  desired_date: '-'
                }
          })
        : []
    }
  },
  methods: {
    ...mapActions({
      getAllDispatchs: 'cubage/getAllDispatchs',
      getAllCubagesMaterials: 'cubage/getAllCubagesMaterials',
      getAllCubages: 'cubage/getAllCubages',
      getAllProjects: 'project/getAllProjects'
    }),
    openModalUploadMaterialsFromExcel() {
      this.dialogUploadMaterialsFromExcel = true
    },
    closeModalUploadMaterialsFromExcel() {
      this.dialogUploadMaterialsFromExcel = false
    },
    async getCubagesData(page = 1) {
      this.filters.page = page
      await this.getAllCubagesMaterials({ page, ...this.filters })
    }
  }
}
</script>

<style></style>
